import { Injectable } from '@angular/core';
import { MicroFEService } from 'pssfe-micro-fe-service';
import { HttpErrorResponse } from '@angular/common/http';
import { TollBillingsRequest, TollContract, TollContractSearchRequest, TollCustomerCreate, TollCustomerEdit, TollCustomersSearchRequest, TollDepartmentCreate, TollDepartmentEdit, TollDepartmentsSearchRequest, TollDeviceLoadItem, TollDevicesSearchRequest, TollGetImportedFilesRequest, TollInvoiceEditpayload, TollLessee, TollLesseeSearchRequest, TollLoadTransactionFileRequest, TollLoadVehiclesFileRequest, TollLotsSearchRequest, TollOrdersSearchRequest, TollTransactionsSearchRequest, TollUser, TollUserConfiguration, TollUsersSearchRequest, TollUtilizerCreate, TollUtilizerEdit, TollUtilizersSearchRequest, TollVehicleCreate, TollVehicleEdit, TollVehiclesSearchRequest, TollVehiclesSubbedSearchRequest, TollVehiclesUtilizersSearchRequest } from '../models/toll.models';
import { TranslateService } from '@ngx-translate/core';
import { distinct, from, of, pipe } from 'rxjs';
import { MfeUtilityService } from './mfe-utility.service';

@Injectable({
  providedIn: 'root'
})
export class TollCommonService {
  private _userConfiguration = new TollUserConfiguration();

  constructor(private _microFeSrv: MicroFEService, private _mfeUtilitySrv: MfeUtilityService, public transalte: TranslateService) {
  }

  //STARTUP
  public async startup() {
    return new Promise(async (resolve) => {
      await this.setUserConfiguration();
      let stylesBaseUri = document.baseURI;
      const urlSearchParams = new URLSearchParams(window.location.search);
      let additionalStyleFolder = urlSearchParams.get("additionalStyleFolder");
      if (stylesBaseUri.indexOf("/fe-") != -1)
        stylesBaseUri = stylesBaseUri.substring(0, stylesBaseUri.indexOf("/fe-") + 1);
      this._microFeSrv.updateStylesByEnvironment((this._mfeUtilitySrv.getEnvironment() as string).toUpperCase().indexOf('STAGE') != -1 ? 'stage' : (this._mfeUtilitySrv.getEnvironment() as string).toUpperCase().indexOf('PROD') != -1 ? 'prod' : 'test', additionalStyleFolder);
      resolve(true);
    });
  }

  //TRANSACTIONS
  public getImportedFiles(payload: TollGetImportedFilesRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/CercaFile`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getPendingTransactions() {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/GetTransazioniDaImportare`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public importTransactionFile(payload: TollLoadTransactionFileRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/CaricaFile`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getLogScarti(path: String) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/GetLogScarti?FullPathFileScarti=${path}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getTransactions(payload: TollTransactionsSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/GetTransazioni`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeTransactionsStatus(transactions: Array<number>, idTipoStatusTransazione: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Transazioni/ModificaStatusTransazioni`, options: {
          body: {
            idTransazioni: transactions,
            idTipoStatusTransazione: idTipoStatusTransazione
          }
        }, method: 'post'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getTransactionsLots(payload: TollLotsSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Fatturazione/CercaLottiEsportati`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editInvoice(payload: TollInvoiceEditpayload) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Fatturazione/AggiornaLottoEsportato`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public downloadInvoice(documentId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Fatturazione/RecuperaPdfTransazioniEsportate?idDocumento=${documentId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public startInvoicing(customerId: number, departmentId: number, lesseeId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Fatturazione/AvviaFatturazione${this.buildQueryParams([{ 'key': 'idCliente', value: customerId }, { 'key': 'idLocatario', value: lesseeId }, { 'key': 'idDipartimento', value: departmentId }])}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getLotsSearchConfiguration() {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollTransazioni/Fatturazione/ConfigurazioneEsportazioneLotti`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //CUSTOMERS
  public getCustomers(payload: TollCustomersSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/RicercaClienti`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createCustomer(payload: TollCustomerCreate) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/InserisciCliente`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editCustomer(payload: TollCustomerEdit) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/ModificaCliente`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getCustomerDetail(customerId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/DettaglioCliente?id=${customerId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeCustomerStatus(customerId: number, statusId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/CambiaStatusCliente?id=${customerId}&value=${statusId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getCustomerTree(customerId?: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/GetAlberaturaClienti${this.buildQueryParams([{ key: 'idCliente', value: customerId }])}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //ORDERS
  public getOrders(payload: TollOrdersSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/CercaOrdini`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getOrdersDashboard() {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/GetOrdiniDaLavorareTotali`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getOrderDetail(orderId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/DettaglioOrdine?idOrdine=${orderId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });

  }
  public takeInChargeOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/PrendiInCaricoOrdine?idOrdine=${orderId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public completeOrder(orderId: number, deviceId: number, deviceShippingAmount: number, deviceActivationAount: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/CompletaOrdine`, method: 'post', options: {
          body: {
            idOrdine: orderId,
            idDispositivo: deviceId,
            costiAttivazione: deviceActivationAount,
            costiSpedizione: deviceShippingAmount,
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public refuseOrded(orderId: number, notes: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/RifiutaOrdine`,
        method: 'post', options: {
          body: {
            idOrdine: orderId,
            noteRifiuto: notes
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public revokeOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/RevocaOrdine?idOrdine=${orderId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public releaseOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/RilasciaOrdine?idOrdine=${orderId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getBulkActivities() {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/GetConfigurazioniUploadMassivoOrdini`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getBulkFile(idConfigurazioneBulkExport: number, idOrdini: number[]) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/GeneraFileEsportazioneMassivaOrdini`, options: {
          body: {
            idConfigurazioneBulkExport: idConfigurazioneBulkExport,
            idOrdini: idOrdini
          }
        }, method: 'post'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public loadBulkFile(idConfigurazioneBulkExport: number, fileOrdiniLavorati: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/ChiudiOrdiniLavoratiMassivamente`, options: {
          body: {
            idConfigurazioneBulkExport: idConfigurazioneBulkExport,
            fileOrdiniLavorati: fileOrdiniLavorati
          }
        }, method: 'post'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //SUBSCRIPTIONS
  public getDevices(payload: TollDevicesSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/GetDispositivi`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public importDevices(payload: any) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/importaDispositivi`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehiclesForSubs(idCliente: number, idEnte: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/attivazioni/GetVeicoloPerSottoscrizione?idCliente=${idCliente}&idEnte=${idEnte}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehiclesSubbed(payload: TollVehiclesSubbedSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/GetVeicoliSottoscritti`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehiclesPhisicalDevices(payload: TollVehiclesSubbedSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/GetAssociazioniDispositiviFisici`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehiclesUtilizers(customerId: number, vehicleId: number, utilizerId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/GetAssociazioneVeicoliUtilizzatori?idCliente=${customerId}&idVeicolo=${vehicleId ? vehicleId : ''}&idUtilizzatore=${utilizerId ? utilizerId : ''}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public activations(payload: { idEnte: number, idCliente: number, lstRichieste: { idTarga: number, tipoDispositivoFittizio: number, tipoDispositivo: number }[] }) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/attivazioni/InserisciOrdineAssociazioneVeicoloDispositivo`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getDeviceAssociations(idEnte: number, idDispositivo: number, idCliente: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/GetAssociazioniDispositivoVeicoloByIdDispositivo?idEnte=${idEnte}&idDispositivo=${idDispositivo}&idCliente=${idCliente}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public deactiveDevices(idCliente: number, idEnte: number, idDipartimento: number, idDispositiviDaCancellare: number[]) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/DisattivaDispositivi`, method: 'post', options: {
          body: {
            idCliente: idCliente,
            idEnte: idEnte,
            idDipartimento: idDipartimento,
            idDispositiviDaCancellare: idDispositiviDaCancellare
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeAssociatedVehicle(deviceId: number, customerId: number, newVehicleId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/CambioVeicolo`, method: 'post', options: {
          body: {
            idDispositivo: deviceId,
            idCliente: customerId,
            idVeicoloNuovo: newVehicleId
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeAssociatedDevice(deviceId: number, customerId: number, motivationType: number, notes: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/SostituzioneDispositivo`, method: 'post', options: {
          body: {
            idDispositivoOrigine: deviceId,
            idDispositivoDestinazione: null,
            idCliente: customerId,
            idTipoMotivazioneOrdine: motivationType,
            noteMotivazioneOrdine: notes
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public closeVehicleService(plateId: number, agencyId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Attivazioni/ChiusuraServizioVeicolo?idTarga=${plateId}&idEnte=${agencyId}`, method: 'get',
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public loadWarehouse(agencyId: number, devices: Array<{ idTipoDispositivo: number, codiceDispositivo: string }>) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/magazzino/CaricaMagazzino?idEnte=${agencyId}`, method: 'post', options: { body: devices } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changePlate(vehicleId: number, customerId: number, departmentId: number, newPlate: string, stateId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Attivazioni/CambioTarga`, method: 'post', options: {
          body: {
            idVeicolo: vehicleId,
            idCliente: customerId,
            idDipartimento: departmentId,
            targaNuova: newPlate,
            idStato: stateId
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //USERS
  public getUsers(payload: TollUsersSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/CercaUtenti`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public async setUserConfiguration(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this._microFeSrv.openLoader();
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/GetConfigurazioneUtente`, method: 'get' }).then(async (res) => {
        this._microFeSrv.dismissLoader();
        if (res.response) {
          if (res.response.httpError) {
            this._microFeSrv.goPageNotFound();
          } else {
            let tmpRes: TollUserConfiguration = res.response.result;
            //filter ids
            for (let key in tmpRes) {//escluso tipoTransazione perché "sconosciuta" ha id -1
              if (!['tipoTransazione'].includes(key) && Array.isArray(tmpRes[key])) {
                tmpRes[key] = tmpRes[key].filter((option) => { return option.id > 0 });
              }
            }
            //tipoDispositivo
            tmpRes.tipoDispositivoFittizio = tmpRes.tipoDispositivo.filter((t) => { return t.isFittizio });
            tmpRes.tipoDispositivo = tmpRes.tipoDispositivo.filter((t) => { return !t.isFittizio });
            //nullYesNoFE
            tmpRes.nullYesNoFE = [{ id: null, descrizione: "All" }, { id: true, descrizione: "Yes" }, { id: false, descrizione: "No" }];
            //isBackOffice
            tmpRes.utenteToll.isBackOffice = tmpRes.utenteToll.ruoliToll ? tmpRes.utenteToll.ruoliToll[0].isBackOffice ? true : false : false;
            //customers (calcolato FE temporaneamente)
            if (!tmpRes.utenteToll.isBackOffice && tmpRes.utenteToll.ruoliToll) {
              let tmpCustomers: Array<{ id: number, descrizione: string }> = [];
              tmpRes.utenteToll.ruoliToll.forEach((role) => {
                if (!tmpCustomers.filter((customer) => { return customer.id == role.idCliente })[0])
                  tmpCustomers.push({ id: role.idCliente, descrizione: '' })
              })
              tmpRes.utenteToll.clienti = tmpCustomers;
            }
            this._userConfiguration = tmpRes;
          }
        } else {
          this._microFeSrv.goPageNotFound();
        }
        resolve(true);
      }).catch((err) => {
        this._microFeSrv.dismissLoader();
        this._microFeSrv.goPageNotFound();
        resolve(true);
      })
    })
  }
  public getUserDetail(userId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBE/utenti/Dettaglio?id=${userId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createUser(payload: TollUser) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/Inserisciutente`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editUser(payload: TollUser) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/ModificaUtente`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeUserStatus(payload: TollUser, newStatus: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/CambiaStatusUtente?idTipoStatus=${newStatus}`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  //VEHICLES
  public getVehicles(payload: TollVehiclesSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/veicoli/RicercaVeicoli`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehicleDetail(vehicleId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/veicoli/DettaglioVeicolo?id=${vehicleId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createVehicle(payload: TollVehicleCreate) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/InserisciVeicolo`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeVehicleStatus(vehicleId: number, statusId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/CambiaStatusVeicolo?id=${vehicleId}&value=${statusId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public addVehicleUtilizer(vehicleId: number, utilizerId: number, customerId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/InserisciAssociazioneVeicoliUtilizzatori`, options: {
          body:
          {
            idVeicolo: vehicleId,
            idUtilizzatore: utilizerId,
            idCliente: customerId
          }
        }, method: 'post'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editVehicle(payload: TollVehicleEdit) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/ModificaVeicolo`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public closeVehicleUtilizer(vehicleId: number, utilizerId: number, customerId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/ChiudiAssociazioneVeicoloUtilizzatore`, options: {
          body: {
            idVeicolo: vehicleId,
            idUtilizzatore: utilizerId,
            idCliente: customerId
          }
        }, method: 'post'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getVehiclesWithoutAssociations(departmentId: number, customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/GetVeicoliSenzaAssociazione?idDipartimentoVeicolo=${departmentId ? departmentId : ''}&idCliente=${customerId}&testo=${searchKey}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public importVehiclesFile(payload: TollLoadVehiclesFileRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/CaricaFile`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public filesSearch() {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/CercaFile`, method: 'post', options: {
          body: {
            pagination: {
              startPosition: 0,
              maxResult: 100,
              orderField: null,
              orderType: 'desc'
            },
            query: {}
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public vehiclesLog(path: String) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/GetLogScarti?FullPathFileScarti=${path}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //DEPARTMENTS
  public getDepartments(payload: TollDepartmentsSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/RicercaDipartimenti`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createDepartment(payload: TollDepartmentCreate) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/InserisciDipartimento`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editDepartment(payload: TollDepartmentEdit) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/ModificaDipartimento`, method: 'post', options: { body: payload } }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getDepartmentDetail(departmentId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/DettaglioDipartimento?id=${departmentId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeDepartmentStatus(departmentId: number, statusId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/CambiaStatusDipartimento?id=${departmentId}&value=${statusId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //UTILIZERS
  public getUtilizersWithoutAssociation(idDipartimentoVeicolo: string, idCliente: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/GetUtilizzatoriSenzaAssociazione?idDipartimentoVeicolo=${idDipartimentoVeicolo}&idCliente=${idCliente}&testo=${searchKey}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getUtilizers(payload: TollUtilizersSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/RicercaUtilizzatori`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getPlates(vehicleId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/GetStoricoTargheVeicolo?idVeicolo=${vehicleId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getUtilizerDetail(utilizerId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/DettaglioUtilizzatore?id=${utilizerId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editUtilizer(payload: TollUtilizerEdit) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/ModificaUtilizzatore`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeUtilizerStatus(utilizerId: number, statusId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/CambiaStatusUtilizzatore?id=${utilizerId}&value=${statusId}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createUtilizer(payload: TollUtilizerCreate) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/InserisciUtilizzatore`, options: { body: payload }, method: 'post' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //MODALS
  public getModalCustomers(searchKey: string, id?: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/RicercaClientiModale${this.buildQueryParams([{ key: 'testo', value: searchKey }, { key: 'id', value: id }])}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalDepartments(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/dipartimenti/RicercaDipartimentiModale?idCliente=${customerId ? customerId : ''}&testo=${searchKey}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalLots(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/Ordini/CercaLotti?idCliente=${customerId ? customerId : ''}&codiceLotto=${searchKey}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalDevices(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollSottoscrizioni/dispositivi/GetDispositivi`, method: 'post', options: {
          body: {
            pagination: {
              startPosition: 0,
              maxResult: 9999,
              orderField: null,
              orderType: 'asc'
            },
            query: {
              id: null,
              codiceDispositivo: searchKey,
              idEnte: null,
              idCliente: customerId,
              idTipoDispositivo: null,
              idDipartimento: null,
              idTipoStatusDispositivo: null,
              idVeicolo: null
            }
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalVehicles(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/RicercaVeicoliModale?idCliente=${customerId ? customerId : ''}&testo=${searchKey}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalPlates(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/RicercaTargheModale?idCliente=${customerId ? customerId : ''}&testo=${searchKey}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalUtilizers(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Utilizzatori/RicercaUtilizzatoriModale?testo=${searchKey}&idCliente=${customerId ? customerId : ''}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalUsers(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollBe/Utenti/RicercaUtentiModale${this.buildQueryParams([{ key: 'testo', value: searchKey }, { key: 'idCliente', value: customerId }])}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalLessees(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Locatari/RicercaLocatarioModale?idCliente=${customerId ? customerId : ''}&testo=${searchKey}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getModalVinNumber(customerId: number, searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Veicoli/RicercaTelaioModale?idCliente=${customerId ? customerId : ''}&testo=${searchKey}`, method: 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //DASHBOARD
  public getDashboard(isBackOffice: boolean) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({//`${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/RicercaClientiModale?testo=${searchKey}`
        url: isBackOffice ? `${this._mfeUtilitySrv.links.apigateway}TollBE/Dashboard/GetConfigDashBoardBO` : `${this._mfeUtilitySrv.links.apigateway}TollBE/Dashboard/GetConfigDashBoardFO`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //BILLINGS
  public getBillings(payload: TollBillingsRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: 'assets/endpoint/toll/billings.json', method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getBillingDetail(billingId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: 'assets/endpoint/toll/billing.json', method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //LESSEES
  public getLessees(payload: TollLesseeSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Locatari/RicercaLocatari`, method: 'post', options: { body: payload }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getLesseeDetail(lesseeId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Locatari/DettaglioLocatario?id=${lesseeId}`, method: 'post',
        options: { body: {} }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createLessee(payload: TollLessee) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Locatari/InserisciLocatario`, method: 'post',
        options: { body: payload }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editLessee(payload: TollLessee) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Locatari/ModificaLocatario`, method: 'post',
        options: { body: payload }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public changeLesseeStatus(lesseeId: number, idTipoStatusLessee: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: 'assets/endpoint/toll/lesee.json', method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //CONTRACTS
  public getContracts(payload: TollContractSearchRequest) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/RicercaContratti`, method: 'post', options: { body: payload }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getContractDetail(contractId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/DettaglioContratto?idContratto=${contractId}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public createContract(payload: TollContract) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/InserisciContratto`, method: 'post',
        options: {
          body: {
            idVeicolo: payload.idVeicolo,
            idCliente: payload.idCliente,
            idLocatario: payload.idLocatario,
            dataInizio: payload.dataInizio,
            dataFine: payload.dataFine,
            numeroContratto: payload.numeroContratto
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public editContract(payload: TollContract) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/ModificaContratto`, method: 'post',
        options: {
          body: {
            idCliente: payload.idCliente,
            idContratto: payload.id,
            nuovaDataInizio: payload.dataInizio,
            nuovaDataFine: payload.dataFine,
            nuovoNumeroContratto: payload.numeroContratto
          }
        }
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public closeContract(contractId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/ChiudiContratto?idContratto=${contractId}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public deleteContract(contractId: number) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/CancellaContratto?idContratto=${contractId}`, method: 'get'
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public checkValidContract(payload: TollContract, action: 'edit' | 'insert' | 'close' | 'delete') {
    return new Promise((resolve, reject) => {
      if (['edit', 'close'].includes(action)) {
        this._microFeSrv.sendCoreHttpRequest({
          url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/ControllaTransazioniPerModificaContratto`, method: 'post',
          options: {
            body: {
              idCliente: payload.idCliente,
              idContratto: payload.id,
              nuovaDataInizio: payload.dataInizio,
              nuovaDataFine: action == 'close' ? null : payload.dataFine,
              nuovoNumeroContratto: payload.numeroContratto
            }
          }
        }).then((res) => {
          if (res.response) {
            if (res.response.httpError) {
              reject(res.response.httpError)
            } else {
              resolve(res.response.result);
            }
          } else {
            reject(new HttpErrorResponse({}))
          }
        }).catch((err) => {
          reject(err);
        })
      }
      else if (action == 'insert') {
        this._microFeSrv.sendCoreHttpRequest({
          url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/ControllaTransazioniVeicoloPerNuovoContratto`, method: 'post',
          options: {
            body: {
              idVeicolo: payload.idVeicolo,
              idCliente: payload.idCliente,
              idLocatario: payload.idLocatario,
              dataInizio: payload.dataInizio,
              dataFine: payload.dataFine,
              numeroContratto: payload.numeroContratto
            }
          }
        }).then((res) => {
          if (res.response) {
            if (res.response.httpError) {
              reject(res.response.httpError)
            } else {
              resolve(res.response.result);
            }
          } else {
            reject(new HttpErrorResponse({}))
          }
        }).catch((err) => {
          reject(err);
        })
      }
      else if (action == 'delete') {
        this._microFeSrv.sendCoreHttpRequest({
          url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/ControllaTransazioniPerCancellazioneContratto?idContratto=${payload.id}&idCliente=${payload.idCliente}`, method: 'get'
        }).then((res) => {
          if (res.response) {
            if (res.response.httpError) {
              reject(res.response.httpError)
            } else {
              resolve(res.response.result);
            }
          } else {
            reject(new HttpErrorResponse({}))
          }
        }).catch((err) => {
          reject(err);
        })
      }
    });
  }
  public getVehiclesWithoutContract(customerId: number, plate: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Contratti/RicercaVeicoliSenzaContrattoByIdCliente?idCliente=${customerId}&targa=${plate}`, method: 'get',
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }

  //GENERIC
  public genericHttpRequest(endpoint: string, payload?: any) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({ url: endpoint, method: payload ? 'post' : 'get' }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getCities(searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/GetDatiCitta?citta=${searchKey}`, method: 'get',
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  public getCaps(searchKey: string) {
    return new Promise((resolve, reject) => {
      this._microFeSrv.sendCoreHttpRequest({
        url: `${this._mfeUtilitySrv.links.apigateway}TollAnagrafiche/Clienti/GetDatiCap?cap=${searchKey}`, method: 'get',
      }).then((res) => {
        if (res.response) {
          if (res.response.httpError) {
            reject(res.response.httpError)
          } else {
            resolve(res.response.result);
          }
        } else {
          reject(new HttpErrorResponse({}))
        }
      }).catch((err) => {
        reject(err);
      })
    });
  }
  //--- end http ---//

  //--- global --//
  //currencyTransform
  public currencyTransform(event) {
    if (event != '' && event != undefined && event != null) {
      if (/^[0-9,.]*$/.test(event)) {
        event = event.toString().replace(/\D/g, "");
        if (['en-US'].includes(navigator.languages[0])) {
          //add dot if required 
          if (event.length > 2) {
            event = event.substring(0, event.length - 2) + "." + event.substring(event.length - 2);
            event = event.toString().replace(/,\./g, ",").replace(/\.,/g, ".");
          }
          if (event.includes(".")) {
            let decimal = event.substring(0, event.indexOf("."));
            decimal = parseInt(decimal).toLocaleString('en-US');
            event = decimal + event.substring(event.indexOf("."), event.length);
          }
        } else {
          //add comma if required 
          if (event.length > 2) {
            event = event.substring(0, event.length - 2) + "," + event.substring(event.length - 2);
            event = event.toString().replace(/,\./g, ",").replace(/\.,/g, ",");
          }
          if (event.includes(",")) {
            let decimal = event.substring(0, event.indexOf(","));
            decimal = parseInt(decimal).toLocaleString('it-IT');
            event = decimal + event.substring(event.indexOf(","), event.length);
          }
        }

      } else {
        event = event.slice(0, -1);
      }
    } else {
      event = '';
    }
    return event;
  }
  public getValueCurrencyTransform(amount: string): number {
    let result: number;
    if (amount == null || amount == undefined || amount == '')
      result = null;
    else {
      let tmpAmount: string = JSON.parse(JSON.stringify(amount));
      if (['en-US'].includes(navigator.languages[0])) {
        tmpAmount = tmpAmount.replace(/,/g, '');
        result = parseFloat(tmpAmount);
      } else {
        tmpAmount = tmpAmount.replace(/\./g, '').replace(/,/g, '.');
        result = parseFloat(tmpAmount);
      }
    }
    return result;
  }
  public getBeDate(dt: Date, type: 'DATE' | 'DATETIME'): string {
    let newDt: string, dtDate: Date;
    if (dt) {
      dtDate = new Date(dt);
      newDt = dtDate.getFullYear() + "-" +
        ("0" + (dtDate.getMonth() + 1)).slice(-2) + "-" +
        ("0" + dtDate.getDate()).slice(-2) +
        (type == 'DATETIME' ? "T" + ("0" + dtDate.getHours()).slice(-2) + ":" + ("0" + dtDate.getMinutes()).slice(-2) : '');
      /*if (['en-US'].includes(navigator.languages[0])) { 
        newDt = dtDate.getFullYear() + "-" +
          ("0" + dtDate.getDate()).slice(-2) + "-" +
          ("0" + (dtDate.getMonth() + 1)).slice(-2) + "-" +
          (type == 'DATETIME' ? "T" + ("0" + dtDate.getHours()).slice(-2) + ":" + ("0" + dtDate.getMinutes()).slice(-2) : '');
      } else {
        newDt = ("0" + dtDate.getDate()).slice(-2) + "-" +
          ("0" + (dtDate.getMonth() + 1)).slice(-2) + "-" +
          dtDate.getFullYear() + (type == 'DATETIME' ? "T" + ("0" + dtDate.getHours()).slice(-2) + ":" + ("0" + dtDate.getMinutes()).slice(-2) : '');
      }*/
    }
    return newDt != null && newDt != undefined && newDt != '' ? newDt : null;
  }
  public getFEDate(dt: Date, type: 'DATE' | 'DATETIME'): string {
    let newDt: string, dtDate: Date;
    if (dt) {
      dtDate = new Date(dt);
      if (['en-US'].includes(navigator.languages[0])) {
        newDt =
          ("0" + (dtDate.getMonth() + 1)).slice(-2) + "/" +
          ("0" + dtDate.getDate()).slice(-2) + "/" +
          dtDate.getFullYear() +
          (type == 'DATETIME' ? (" " + ("0" + dtDate.getHours()).slice(-2) + ":" + ("0" + dtDate.getMinutes()).slice(-2)) : '');
      } else {
        newDt =
          ("0" + dtDate.getDate()).slice(-2) + "/" +
          ("0" + (dtDate.getMonth() + 1)).slice(-2) + "/" +
          dtDate.getFullYear() +
          (type == 'DATETIME' ? (" " + ("0" + dtDate.getHours()).slice(-2) + ":" + ("0" + dtDate.getMinutes()).slice(-2)) : '');
      }
    }
    return newDt != null && newDt != undefined && newDt != '' ? newDt : null;
  }
  public async getDateFormatString(): Promise<string> {
    return new Promise((resolve) => {
      this.transalte.get('toll').subscribe((labels) => {
        resolve(['en-US'].includes(navigator.languages[0]) ? labels['enDateFormat'] : labels['itDateFormat']);
      })
    })

  }
  public async getDateTimeFormatString(): Promise<string> {
    return new Promise((resolve) => {
      this.transalte.get('toll').subscribe((labels) => {
        resolve(['en-US'].includes(navigator.languages[0]) ? labels['enDateTimeFormat'] : labels['itDateTimeFormat']);
      })
    })
  }
  public getUserConfiguration(): TollUserConfiguration {
    return this._userConfiguration;
  }
  public buildQueryParams(properties: Array<{ key: string, value: any }>) {
    let qp: string = '?';
    properties = properties.filter((p) => { return p.value != null && p.value != undefined && p.value != '' });
    properties.forEach((p, index) => {
      qp = qp + (index == 0 ? (p.key + '=' + p.value) : ('&' + p.key + '=' + p.value));
    })
    return qp;
  }
  public async awaitTimeout(timeoutMs: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, timeoutMs)
    })
  }
  //--- end global (isBackOffice lo mettiamo in config utente) ---//
}
